function Card(props) {
  return (
    <div className="card">
      <br />
      {props.img && (
      <div className="text-center">
        <img src={props.img} className="text-center img-fluid" />
      </div>
      )}
      <div className="text-center">
        <h3 className="card-title">{props.title}</h3>
      </div>
      <div className="p-3">
        <p className="card-text" dangerouslySetInnerHTML={{ __html: props.text }}>
        </p>
      </div>
    </div>
  );
}
export default Card;
